import React, { useState, useEffect } from "react";
import { Route, Navigate, Routes, useNavigate } from "react-router-dom";
import {
  SignIn,
  CustomAppBar,
  ResetPassword,
  ForgotPassword,
} from "@shared/components/lib/index";
import conf from "common-utils/conf";
import { PAGES, SUPPORT_PAGES } from "./routers";
import { useLocalStorage } from "usehooks-ts";
import { postRequestUI } from "common-utils/utils/api";

export default function AdminApp() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [userType, setUserType] = useState(null);
  const [auth, setAuth] = useLocalStorage("auth", null);
  const navigate = useNavigate();

  useEffect(() => {
    authenticate();
  }, [auth]);

  const authenticate = async () => {
    try {
      if (!auth) {
        setUserType(null);
        return;
      }
      const authRole = auth.role.userType;
      if (authRole) {
        await redirectIfNotAdmin(authRole);
        setUserType(authRole);
        navigate("/orders");
      }
    } catch (error) {
      setUserType(null);
    }
  };

  const redirectIfNotAdmin = async (userType) => {
    if (userType === "carrier" || userType === "retailer") {
      await signOut();
      return;
    }
  };

  const signOut = async () => {
    await postRequestUI("/logout");
    setAuth(null);
    navigate("/login");
  };

  const handleLogout = async () => {
    setUserType(null);
    setAnchorEl(null);
    signOut();
  };

  const pages = userType === "support" ? SUPPORT_PAGES : PAGES;

  if (!auth) {
    return (
      <Routes>
        <Route path="/forgot-password/:token?" element={<ForgotPassword />} />
        <Route
          path="/reset-password/:token?"
          element={<ResetPassword appUrl={conf.admin} />}
        />
        <Route path="/login" element={<SignIn />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  }

  if (
    userType === "admin" ||
    userType === "superAdmin" ||
    userType === "support"
  ) {
    return (
      <>
        <CustomAppBar
          pages={pages}
          appName={"A"}
          mobileOpen={mobileOpen}
          setMobile={setMobileOpen}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          handleLogout={handleLogout}
        />
        <Routes>
          <Route path="/" element={<Navigate to="/orders" replace />} />
          {pages.map((route) => (
            <Route key={route.name} path={route.path} element={route.element} />
          ))}
        </Routes>
      </>
    );
  }

  return <></>;
}
