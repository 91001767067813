import {
  Grid,
  Box,
  TableContainer,
  Paper,
  Button,
  TextField,
  MenuItem,
  Dialog,
  Slide,
  Toolbar,
  IconButton,
  FormControl,
  Chip,
  Autocomplete,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useState } from "react";
import {
  TableComponent,
  IconButton as CIconButton,
  PageTitle,
} from "@shared/components/lib/index";
import useTheme from "@mui/material/styles/useTheme";
import { useQuery, useMutation, useQueryClient } from "react-query";
import DialogAppBar from "./DialogAppBar";
import CloseIcon from "@mui/icons-material/Close";
import { CurrencyInput } from "@shared/components/lib/index";
import {
  getRequestUI,
  postRequestUI,
  putRequestUI,
  deleteRequestUI,
} from "common-utils/utils/api";
import EditIcon from "@mui/icons-material/Edit";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RetailerPriceProfileTab = ({ retailerId, prerequisites }) => {
  const theme = useTheme();
  const [newDialogOpen, setNewDialogOpen] = useState(false);
  const [carrierId, setCarrierId] = useState(undefined);
  const [parcelFee, setParcelFee] = useState(0);
  const [cogs, setCogs] = useState(0);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [extraFees, setExtraFees] = useState({});
  const [priceProfileId, setPriceProfileId] = useState(undefined);
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [selfBilling, setSelfBilling] = useState(false);
  const retailer = prerequisites?.retailers?.find((r) => r.id === retailerId);
  const carriers = retailer?.carriers || [];
  const statuses = prerequisites?.statuses || [];

  const feeIds = {
    xlParcelFee: "xl_parcel_fee",
    weekendFee: "weekend_fee",
    insuranceFee: "insurance_fee",
    signatureFee: "signature_fee",
    noNeighborFee: "no_neighbor_fee",
    ageCheckFee: "age_check_fee",
    signatureCogs: "signature_cogs",
    noNeighborCogs: "no_neighbor_cogs",
    ageCheckCogs: "age_check_cogs",
  };

  const feeValueOf = (feeId, fallbackValue = 0) =>
    extraFees[feeId]?.value ?? fallbackValue;
  const feeOverwriteOf = (feeId) => extraFees[feeId]?.overwrite ?? false;

  const { data: sizes = [] } = useQuery(
    ["carrier-sizes", carrierId],
    async () => {
      if (carrierId) {
        const result = await getRequestUI(`sizes/carrier/${carrierId}`);
        return result.map((size) => ({ id: size.sizeId, name: size.name }));
      }
      return [];
    },
    {
      enabled: !!carrierId,
    },
  );

  const handleCarrierChange = (e) => {
    const newCarrierId = e.target.value;
    setCarrierId(newCarrierId);
    setSelectedSizes([]);
  };

  const handleOpenDialog = () => {
    clear();
    setNewDialogOpen(true);
  };

  const clear = () => {
    setCarrierId(null);
    setParcelFee(0);
    setCogs(0);
    setSelectedStatuses([]);
    setPriceProfileId(null);
    setExtraFees({});
    setSelectedSizes([]);
    setSelfBilling(false);
  };

  const handleSave = () => {
    const data = {
      carrierId,
      parcelFee: Number(parcelFee),
      cogs: Number(cogs),
      retailerId,
      statuses: selectedStatuses.map((status) => status.id),
      extraFees: Object.fromEntries(
        Object.keys(feeIds).map((key) => [
          feeIds[key],
          {
            value: Number(feeValueOf(feeIds[key], 0)),
            overwrite: feeOverwriteOf(feeIds[key]),
          },
        ]),
      ),
      sizes: selectedSizes.map((size) => size.id),
      selfBilling,
    };
    if (priceProfileId) {
      updatePriceProfileMutation.mutate({ ...data, id: priceProfileId });
    } else {
      createPriceProfileMutation.mutate(data);
    }
  };

  const handleFeeChange = (id, value) => {
    setExtraFees((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        value: value,
      },
    }));
  };

  const handleOverwriteChange = (e) => {
    const { id, checked } = e.target;

    setExtraFees((prev) => ({
      ...prev,
      [id]: {
        ...prev[id],
        overwrite: checked,
      },
    }));
  };

  const {
    data: priceProfiles,
    refetch,
    error: refetchError,
  } = useQuery(["price-profile", retailerId], async () => {
    const result = await getRequestUI(`retailer/${retailerId}/price-profile`);
    return result;
  });

  if (refetchError) {
    console.error("Refetch failed:", refetchError);
  }

  const createPriceProfileMutation = useMutation(
    (data) => postRequestUI(`retailer/price-profile/${retailerId}`, data),
    {
      onSuccess: () => {
        refetch();
        setNewDialogOpen(false);
      },
      onError: (error) => {
        console.error("Failed to save the price profile:", error);
      },
    },
  );

  const updatePriceProfileMutation = useMutation(
    (data) => putRequestUI(`retailer/price-profile/${data.id}`, data),
    {
      onSuccess: () => {
        refetch();
        setNewDialogOpen(false);
      },
      onError: (error) => {
        console.error("Failed to update the price profile:", error);
      },
    },
  );

  const editProfile = (profile) => {
    setCarrierId(profile.carrierId);
    setParcelFee(profile.parcelFee);
    setCogs(profile.cogs);
    setSelectedStatuses(
      profile.statuses.map((status) =>
        statuses.find((s) => s.id === status.id),
      ),
    );
    setSelectedSizes(profile.sizes);
    const newExtraFees = Object.fromEntries(
      [...profile.additionalCOGSFees, ...profile.additionalParcelFees].map(
        (fee) => [
          fee.id,
          {
            value: fee.value,
            overwrite: fee.overwrite,
          },
        ],
      ),
    );
    setExtraFees(newExtraFees);
    setSelfBilling(profile.selfBilling);
    setPriceProfileId(profile.id);
    setNewDialogOpen(true);
  };

  const deletePriceProfileMutation = useMutation(
    (profileId) => deleteRequestUI(`retailer/price-profile/${profileId}`),
    {
      onSuccess: () => {
        useQueryClient.invalidateQueries(["price-profile", retailerId]);
        refetch();
      },
      onError: (error) => {
        console.error("Failed to delete the price profile:", error);
      },
    },
  );

  const handleDeleteProfile = (profileId) => {
    deletePriceProfileMutation.mutate(profileId);
  };
  return (
    <>
      <Grid container>
        <TableContainer component={Paper}>
          <div style={{ padding: 15 }}>
            <PageTitle setTitle={"Price Profiles"} />
          </div>
          <Button
            variant="outlined"
            size="small"
            color="primary"
            onClick={handleOpenDialog}
          >
            Add Price Profile
          </Button>
          <TableComponent
            headers={[
              "Carrier",
              "Parcel Fee",
              "COGS",
              "Billiable Statuses",
              "Actions",
            ]}
            rowData={priceProfiles || []}
            cellComponents={[
              (profile) =>
                carriers.find((c) => c.carrierId === profile.carrierId)?.name ||
                "Unknown",
              (profile) => profile.parcelFee,
              (profile) => profile.cogs,
              (profile) => (
                <>
                  {profile.statuses.map((status) => (
                    <Chip key={status.id} label={status.name} />
                  ))}
                </>
              ),
              (profile) => (
                <>
                  <CIconButton
                    icon={<EditIcon />}
                    onClick={() => editProfile(profile)}
                  />

                  <CIconButton
                    icon="delete"
                    onClick={() => handleDeleteProfile(profile.id)}
                  />
                </>
              ),
            ]}
          />
        </TableContainer>

        {newDialogOpen && (
          <Dialog
            fullScreen
            open={newDialogOpen}
            onClose={() => setNewDialogOpen(false)}
            TransitionComponent={Transition}
          >
            <DialogAppBar>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  onClick={() => setNewDialogOpen(false)}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
                <Button autoFocus color="inherit" onClick={handleSave}>
                  {priceProfileId ? "Update" : "Save"}
                </Button>
              </Toolbar>
            </DialogAppBar>

            <Grid container spacing={2} padding={2}>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {priceProfileId ? "Edit Price Profile" : "Add Price Profile"}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <TextField
                    select
                    label="Select Carrier"
                    value={carrierId}
                    onChange={handleCarrierChange}
                    margin="dense"
                  >
                    {carriers.length > 0 ? (
                      carriers.map((carrier) => (
                        <MenuItem
                          key={carrier.carrierId}
                          value={carrier.carrierId}
                        >
                          {carrier.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No carriers available</MenuItem>
                    )}
                  </TextField>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    options={sizes}
                    getOptionLabel={(option) => option.name || ""}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    value={selectedSizes}
                    onChange={(event, newValue) => {
                      setSelectedSizes(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select XL Surcharge Sizes"
                        margin="dense"
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={4}>
                <FormControl fullWidth>
                  <Autocomplete
                    multiple
                    options={statuses}
                    getOptionLabel={(option) => option.name}
                    value={selectedStatuses}
                    onChange={(event, newValue) =>
                      setSelectedStatuses(newValue)
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select Billiable Statuses"
                        margin="dense"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <TextField
                    select
                    label="Self-billing"
                    value={selfBilling ? "true" : "false"}
                    onChange={(e) => setSelfBilling(e.target.value === "true")}
                    margin="dense"
                    style={{ marginTop: "23px" }}
                  >
                    <MenuItem value="true">Yes</MenuItem>
                    <MenuItem value="false">No</MenuItem>
                  </TextField>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <CurrencyInput
                    label="Parcel Fee"
                    value={parcelFee}
                    onChange={(value) => setParcelFee(value)}
                    name="parcelFee"
                    currency="€"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl fullWidth>
                  <CurrencyInput
                    label="COGS"
                    value={cogs}
                    onChange={(value) => setCogs(value)}
                    name="cogs"
                    currency="€"
                  />
                </FormControl>
              </Grid>

              <Grid item xs={12}>
                <Typography variant="subtitle1">
                  Additional Parcel Fees
                </Typography>
              </Grid>

              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <CurrencyInput
                    label="Weekend Fee"
                    value={feeValueOf(feeIds.weekendFee)}
                    onChange={(value) =>
                      handleFeeChange(feeIds.weekendFee, value)
                    }
                    name="weekendFee"
                    currency="€"
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={feeIds.weekendFee}
                      checked={feeOverwriteOf(feeIds.weekendFee)}
                      onChange={handleOverwriteChange}
                    />
                  }
                  label="Overwrite"
                />
              </Grid>
              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <CurrencyInput
                    label="XL Parcel Fee"
                    value={feeValueOf(feeIds.xlParcelFee)}
                    onChange={(value) =>
                      handleFeeChange(feeIds.xlParcelFee, value)
                    }
                    name="xlParcelFee"
                    currency="€"
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={feeIds.xlParcelFee}
                      checked={feeOverwriteOf(feeIds.xlParcelFee)}
                      onChange={handleOverwriteChange}
                    />
                  }
                  label="Overwrite"
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <CurrencyInput
                    label="Insurance Fee"
                    value={feeValueOf(feeIds.insuranceFee)}
                    onChange={(value) =>
                      handleFeeChange(feeIds.insuranceFee, value)
                    }
                    name="insuranceFee"
                    currency="€"
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={feeIds.insuranceFee}
                      checked={feeOverwriteOf(feeIds.insuranceFee)}
                      onChange={handleOverwriteChange}
                    />
                  }
                  label="Overwrite"
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <CurrencyInput
                    label="Signature Fee"
                    value={feeValueOf(feeIds.signatureFee)}
                    onChange={(value) =>
                      handleFeeChange(feeIds.signatureFee, value)
                    }
                    name="signatureFee"
                    currency="€"
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={feeIds.signatureFee}
                      checked={feeOverwriteOf(feeIds.signatureFee)}
                      onChange={handleOverwriteChange}
                    />
                  }
                  label="Overwrite"
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <CurrencyInput
                    label="No Neighbor Fee"
                    value={feeValueOf(feeIds.noNeighborFee)}
                    onChange={(value) =>
                      handleFeeChange(feeIds.noNeighborFee, value)
                    }
                    name="noNeighborFee"
                    currency="€"
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={feeIds.noNeighborFee}
                      checked={feeOverwriteOf(feeIds.noNeighborFee)}
                      onChange={handleOverwriteChange}
                    />
                  }
                  label="Overwrite"
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <CurrencyInput
                    label="Age Check Fee"
                    value={feeValueOf(feeIds.ageCheckFee)}
                    onChange={(value) =>
                      handleFeeChange(feeIds.ageCheckFee, value)
                    }
                    name="ageCheckFee"
                    currency="€"
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={feeIds.ageCheckFee}
                      checked={feeOverwriteOf(feeIds.ageCheckFee)}
                      onChange={handleOverwriteChange}
                    />
                  }
                  label="Overwrite"
                />
              </Grid>

              {/* Additional COGS Fees */}
              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <CurrencyInput
                    label="Signature COGS"
                    value={feeValueOf(feeIds.signatureCogs)}
                    onChange={(value) =>
                      handleFeeChange(feeIds.signatureCogs, value)
                    }
                    name="signatureCogs"
                    currency="€"
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={feeIds.signatureCogs}
                      checked={feeOverwriteOf(feeIds.signatureCogs)}
                      onChange={handleOverwriteChange}
                    />
                  }
                  label="Overwrite"
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <CurrencyInput
                    label="No Neighbor COGS"
                    value={feeValueOf(feeIds.noNeighborCogs)}
                    onChange={(value) =>
                      handleFeeChange(feeIds.noNeighborCogs, value)
                    }
                    name="noNeighborCogs"
                    currency="€"
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={feeIds.noNeighborCogs}
                      checked={feeOverwriteOf(feeIds.noNeighborCogs)}
                      onChange={handleOverwriteChange}
                    />
                  }
                  label="Overwrite"
                />
              </Grid>

              <Grid item xs={6} sm={4}>
                <FormControl fullWidth>
                  <CurrencyInput
                    label="Age Check COGS"
                    value={feeValueOf(feeIds.ageCheckCogs)}
                    onChange={(value) =>
                      handleFeeChange(feeIds.ageCheckCogs, value)
                    }
                    name="ageCheckCogs"
                    currency="€"
                  />
                </FormControl>
                <FormControlLabel
                  control={
                    <Checkbox
                      id={feeIds.ageCheckCogs}
                      checked={feeOverwriteOf(feeIds.ageCheckCogs)}
                      onChange={handleOverwriteChange}
                    />
                  }
                  label="Overwrite"
                />
              </Grid>
            </Grid>
          </Dialog>
        )}
      </Grid>
    </>
  );
};

export default RetailerPriceProfileTab;
