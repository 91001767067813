import React, { useState, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import TableFilter from "../components/TableFilter";
import Snackbar from "@mui/material/Snackbar";
import Grid from "@mui/material/Grid";
import {
  getRequestUI,
  postRequestUI,
  putRequestUI,
  deleteRequestUI,
} from "common-utils/utils/api";
import DialogAppBar from "../components/DialogAppBar";
import Checkbox from "@mui/material/Checkbox";
import EditIcon from "@mui/icons-material/Edit";

import {
  AddButton,
  ButtonProgress,
  IconButton as CIconButton,
  PageTitle,
  TableComponent,
  MainBox,
  TabPanel,
} from "@shared/components/lib/index";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import RetailerFormTab from "../components/RetailerFormTab";
import RetailerBrandingFormTab from "../components/RetailerBrandingFormTab";
import RetailerCarrierTab from "../components/RetailerCarrierTab";
import RetailerInsuranceTab from "../components/RetailerInsuranceTab";
import RetailerIntegrationTab from "../components/RetailerIntegrationTab";
import RetailerFeatureTab from "../components/RetailerFeatureTab";
import { useQuery, useMutation, useQueryClient } from "react-query";
import RetailerPromotionsTab from "../components/RetailerPromotionsTab";
import RetailerNotificationTab from "../components/RetailerNotificationTab";
import RetailerReturnAddressTab from "../components/RetailerReturnAddressTab";
import RetailerFAQTab from "../components/RetailerFAQTab";
import RetailerETATab from "../components/RetailerETATab";
import RetailerPriceProfileTab from "../components/RetailerPriceProfileTab";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Retailers(props) {
  const queryClient = useQueryClient();
  const [open, setOpen] = useState(false);
  const [retailers, setRetailers] = useState([]);
  const [deletingRetailer, setDeletingRetailer] = useState(null);
  const [edit, setEdit] = useState(false);
  const [showProgress, setShowProgress] = useState(false);
  const [newRetailerSuccess, setNewRetailerSuccess] = useState(false);
  const [isCreatingNew, setCreatingNew] = useState(false);
  const [tagValue, setTagValue] = useState(0);
  const [selectedRetailer, setSelectedRetailer] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [showError, setShowError] = useState(false);
  const [selectedRetailers, setSelectedRetailers] = useState({ retailers: [] });
  const [initialRetailers, setInitialRetailers] = useState([]);
  const prerequisites = useQuery(
    ["admin-retailer-prerequisites", selectedRetailers],
    async () => {
      const retailersIds = selectedRetailers.retailers.map((el) => el.id);
      const result = await getRequestUI("/admin/retailer-prerequisites", {
        retailers: retailersIds,
      });
      return result;
    },
    {
      onSuccess: (data) => setInitialRetailers(data.retailers),
    }
  );

  const createMutation = useMutation(saveRetailer, {
    onSuccess: () => {
      setOpen(false);
      prerequisites.refetch();
      setCreatingNew(false);
    },
  });

  const deleteMutation = useMutation(deleteRetailer, {
    onSuccess: () => {
      prerequisites.refetch();
    },
    onError: (error) => {
      if (error.code === "invalid-argument") {
        setErrorMessage(error.message);
        setShowError(true);
      }
    },
  });

  const updateMutation = useMutation(updateRetailer, {
    onSuccess: () => {
      handleClose();
      setCreatingNew(false);
      prerequisites.refetch();
    },
  });

  const { data = [] } = prerequisites;

  const handleClickOpen = () => {
    setOpen(true);
  };

  async function saveRetailer(data) {
    setCreatingNew(true);
    await postRequestUI("/retailer", data);
  }

  async function deleteRetailer(id) {
    await deleteRequestUI(`/retailer`, { id });
    prerequisites.refetch();
  }

  async function updateRetailer(data) {
    putRequestUI("/retailer", data);
    setCreatingNew(true);
    prerequisites.refetch();
  }

  const validateEmails = (values) => {
    if (!values || values.length === 0) return true;
    return values.some((val) =>
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(val)
    );
  };

  const handleSave = (data) => {
    if (data.notificationEmail && !validateEmails(data.notificationEmail)) {
      setErrorMessage("Please fill correct notification email format");
      setShowError(true);
      return;
    }
    createMutation.mutate(data);
    setCreatingNew(true);
    handleClose();
  };

  const handleEditRetailer = (data) => {
    if (data.notificationEmail && !validateEmails(data.notificationEmail)) {
      setErrorMessage("Please fill correct notification email format");
      setShowError(true);
      return;
    }
    updateMutation.mutate(data);
    setCreatingNew(true);
    handleClose();
  };

  const editRetailer = (retailer) => {
    setEdit(true);
    setSelectedRetailer(retailer);
    setTagValue(0);
  };

  const handleDelete = (data) => {
    deleteMutation.mutate(data);
  };

  const handleChangeTagValue = (event, newValue) => {
    setTagValue(newValue);
  };

  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setTagValue(null);
  };

  const retailerTableData = {
    headers: [
      "",
      "Name",
      "Contact number",
      "Email",
      "Carriers",
      "Partner",
      "API Key",
      "Age Check",
      "Deliver at neighbors",
      "",
    ],
    body: [
      (retailer) => (
        <Button
          endIcon={<EditIcon />}
          data-testid="edit_retailer_btn"
          onClick={() => editRetailer(retailer)}
        />
      ),
      (retailer) => retailer.name,
      (retailer) => retailer.number,
      (retailer) => retailer.email,
      (retailer) =>
        retailer.carriers &&
        retailer.carriers.map(({ name }) => name).join(", "),
      (retailer) => <Checkbox checked={retailer.partner} readOnly />,
      (retailer) => (
        <Grid
          item
          alignItems={"center"}
          justifyContent={"space-around"}
          style={{ display: "flex" }}
        >
          <CopyToClipboard text={retailer.apiKey}>
            <CIconButton icon="content_copy" />
          </CopyToClipboard>
          <TextField
            value={retailer.apiKey}
            variant="outlined"
            disabled
            style={{ minWidth: 200 }}
          ></TextField>
        </Grid>
      ),
      (retailer) => <Checkbox checked={retailer.ageCheck} readOnly />,
      (retailer) => <Checkbox checked={retailer.deliverAtNeighbors} readOnly />,
      (retailer) =>
        deletingRetailer === retailer.id ? (
          <CircularProgress size={24} />
        ) : (
          <CIconButton
            data-testid="delete_retailer_btn"
            icon="delete"
            onClick={() => handleDelete(retailer.id)}
          />
        ),
    ],
  };

  const getErrorMessage = () => {
    if (prerequisites.isError) {
      return prerequisites.error.message;
    }
    if (createMutation.isError) {
      return createMutation.error.message;
    }
    if (updateMutation.isError) {
      return updateMutation.error.message;
    }
    if (deleteMutation.isError) {
      return deleteMutation.error.message;
    }
    if (showError) {
      return errorMessage;
    }
    return "";
  };
  const onCloseErrorMessage = () => {
    setErrorMessage("");
    setShowError(false);
  };

  return (
    <MainBox isFullWidth={true}>
      <AddButton onClick={handleClickOpen} />
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!getErrorMessage()}
        onClose={onCloseErrorMessage}
        autoHideDuration={3000}
      >
        <Alert severity="error">{getErrorMessage()}</Alert>
      </Snackbar>
      <Dialog
        fullScreen
        open={open || edit}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogAppBar>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              data-testid="close_retailer_btn"
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </DialogAppBar>
        <DialogContent>
          {edit && (
            <Paper>
              <Tabs
                value={tagValue}
                onChange={handleChangeTagValue}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Info" />
                <Tab data-testid="branding" label="Branding" />
                <Tab data-testid="retailer-carriers" label="Carriers" />

                <Tab
                  data-testid="retailer-features"
                  label="Retailer Features"
                />
                <Tab data-testid="retailer-insurances" label="Insurances" />
                <Tab data-testid="retailer-integration" label="Integration" />
                <Tab data-testid="retailer-integration" label="Promotions" />
                <Tab
                  data-test-id="status-notification"
                  label="Status notifications"
                />
                <Tab data-test-id="return-address" label="Return Address" />
                <Tab data-test-id="faq" label="FAQ" />
                <Tab data-test-id="eta" label="ETA" />
                <Tab
                  data-test-id="retailer-price-profile"
                  label="Retailer Price Profile"
                />
              </Tabs>
              <TabPanel value={tagValue} index={0}>
                <RetailerFormTab
                  retailer={selectedRetailer}
                  edit={edit}
                  isCreatingNew={isCreatingNew}
                  tagValue={tagValue}
                  handleEditRetailer={handleEditRetailer}
                  handleSave={handleSave}
                />
              </TabPanel>
              <TabPanel value={tagValue} index={1}>
                <RetailerBrandingFormTab
                  retailer={selectedRetailer}
                  isCreatingNew={isCreatingNew}
                  handleClose={handleClose}
                />
              </TabPanel>
              <TabPanel value={tagValue} index={2}>
                <RetailerCarrierTab retailerId={selectedRetailer.id} />
              </TabPanel>
              <TabPanel value={tagValue} index={3}>
                <RetailerFeatureTab
                  retailer={selectedRetailer}
                  handleClose={handleClose}
                />
              </TabPanel>
              <TabPanel value={tagValue} index={4}>
                <RetailerInsuranceTab retailerId={selectedRetailer.id} />
              </TabPanel>
              <TabPanel value={tagValue} index={5}>
                <RetailerIntegrationTab retailerId={selectedRetailer.id} />
              </TabPanel>
              <TabPanel value={tagValue} index={6}>
                <RetailerPromotionsTab retailerId={selectedRetailer.id} />
              </TabPanel>
              <TabPanel value={tagValue} index={7}>
                <RetailerNotificationTab
                  statuses={data.statuses}
                  reasons={data.reasons}
                  types={data.types}
                  retailerId={selectedRetailer.id}
                />
              </TabPanel>
              <TabPanel value={tagValue} index={8}>
                <RetailerReturnAddressTab retailerId={selectedRetailer.id} />
              </TabPanel>
              <TabPanel value={tagValue} index={9}>
                <RetailerFAQTab retailerId={selectedRetailer.id} />
              </TabPanel>
              <TabPanel value={tagValue} index={10}>
                <RetailerETATab
                  retailerId={selectedRetailer.id}
                  prerequisites={data}
                />
              </TabPanel>
              <TabPanel value={tagValue} index={11}>
                <RetailerPriceProfileTab
                  retailerId={selectedRetailer.id}
                  prerequisites={data}
                />
              </TabPanel>
            </Paper>
          )}
          {open && (
            <RetailerFormTab
              edit={edit}
              isCreatingNew={isCreatingNew}
              tagValue={tagValue}
              handleEditRetailer={handleEditRetailer}
              handleSave={handleSave}
            />
          )}
        </DialogContent>
      </Dialog>
      <Box component="span" display={showProgress ? "block" : "none"}>
        <LinearProgress />
      </Box>
      <TableContainer component={Paper}>
        <div style={{ padding: 15 }}>
          <PageTitle setTitle={"Retailers"} />
        </div>
        <Grid container xs={12}>
          <TableFilter
            tableFilter={[
              {
                data: initialRetailers,
                label: "Retailers",
                key: "retailers",
                id: "retailers",
              },
            ]}
            selectedFilterData={selectedRetailers}
            setSelectedFilterData={setSelectedRetailers}
          />
        </Grid>
        <TableComponent
          headers={retailerTableData.headers}
          rowData={data.retailers || []}
          cellComponents={retailerTableData.body}
        />
      </TableContainer>
    </MainBox>
  );
}

export default Retailers;
