import React, { useState } from "react";
import { useQuery, useMutation } from "react-query";
import { format } from "date-fns";
import { Button, Snackbar, Grid, Paper, Alert } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import TableContainer from "@mui/material/TableContainer";
import { ButtonProgress } from "@shared/components/lib/index";

import {
  TableComponent,
  MainBox,
  Filters,
  PageTitle,
} from "@shared/components/lib/index";
import { getRequestUI, postRequestUI } from "common-utils/utils/api";
import useTheme from "@mui/material/styles/useTheme";

const useStyles = (theme) => ({
  infoButton: {
    "& svg": {
      color: "#000000",
    },
  },
  filterBlock: {
    padding: 2,
  },
});
const BillingExports = () => {
  const theme = useTheme();
  const styles = useStyles(theme);

  const [billingExports, setBillingExports] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [selectedStartBillingDate, setSelectedStartBillingDateFilter] =
    useState(new Date());
  const [selectedEndBillingDate, setSelectedEndBillingDateFilter] = useState(
    new Date(),
  );
  const [selectedRetailers, setSelectedRetailers] = useState([]);

  const billinExportData = useQuery(
    ["billing-exports"],
    async () => {
      const result = await getRequestUI("/export-billing");
      return result;
    },
    {
      onSuccess: (data) => setBillingExports(data),
      onError: () => {
        setErrorMessage("Cannot get billing exports. Please try again.");
      },
    },
  );

  const { data: prerequisites } = useQuery(
    ["initAdminOrderPrerequisites"],
    async () => {
      const result = await getRequestUI("/orders-prerequisites");
      return result;
    },
  );

  const exportMutation = useMutation(
    async (requestPayload) => {
      const response = await postRequestUI("/billing-exports", requestPayload);
      return response;
    },
    {
      onSuccess: () => {
        billinExportData.refetch();
      },
      onError: () => {
        setErrorMessage("Error creating export");
      },
    },
  );

  const clickOnDownloadFile = async (id) => {
    try {
      setDownloadLoading(true);
      const response = await getRequestUI("/export-orders/billing/download", {
        billingExportId: id,
      });
      if (!response) {
        setErrorMessage("Cannot get signed URL");
      }
      window.open(response.signedUrl, "_blank");
      setDownloadLoading(false);
    } catch (e) {
      setErrorMessage("Cannot get signed URL");
      setDownloadLoading(false);
    }
  };

  const handleExportClick = () => {
    const retailerIds = selectedRetailers.map((retailer) => retailer.id);
    const requestPayload = {
      startDate: format(selectedStartBillingDate, "yyyy-MM-dd"),
      endDate: format(selectedEndBillingDate, "yyyy-MM-dd"),
      retailers: retailerIds.length > 0 ? retailerIds : undefined,
    };

    exportMutation.mutate(requestPayload);
  };
  const retailers =
    prerequisites?.retailers?.map((retailer) => ({
      id: retailer.id,
      name: retailer.name,
    })) || [];

  const billingExportTableData = {
    headers: ["File Name", "Status", "Created At", "Download"],
    body: [
      (billingExport) => billingExport.fileName,
      (billingExport) => billingExport.status.toUpperCase(),
      (billingExport) => new Date(`${billingExport.date}`).toLocaleString(),
      (billingExport) => (
        <Button
          endIcon={<DownloadIcon />}
          onClick={() => clickOnDownloadFile(billingExport.id)}
          disabled={!billingExport.isCompleted || downloadLoading}
        />
      ),
    ],
  };

  return (
    <MainBox>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={!!errorMessage}
        autoHideDuration={3000}
        onClose={() => setErrorMessage("")}
      >
        <Alert severity="error">{errorMessage}</Alert>
      </Snackbar>

      <PageTitle title={"Billing Exports"} />

      <Grid container sx={styles.filterBlock} spacing={1} alignItems="center">
        <Grid item xs={12} sm={10}>
          <Filters
            selectedRetailersFilter={selectedRetailers}
            setSelectedRetailersFilter={setSelectedRetailers}
            retailers={retailers}
            selectedStartPickUpDateFilter={selectedStartBillingDate}
            setSelectedStartPickUpDateFilter={setSelectedStartBillingDateFilter}
            selectedEndPickUpDateFilter={selectedEndBillingDate}
            setSelectedEndPickUpDateFilter={setSelectedEndBillingDateFilter}
            hideApplyFilter={true}
            hideExport={true}
            dateName={"billing date"}
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            disabled={exportMutation.isLoading}
            onClick={handleExportClick}
            variant="contained"
            color="primary"
            sx={styles.buttonSubmit}
            margin="normal"
            fullWidth
          >
            Export
            {exportMutation.isLoading && <ButtonProgress size={15} />}
          </Button>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <TableComponent
          headers={billingExportTableData.headers}
          rowData={billingExports || []}
          cellComponents={billingExportTableData.body}
        />
      </TableContainer>
    </MainBox>
  );
};

export default BillingExports;
